<template>
  <div>
    <div
      class="divider-icon2 my-3 divider-margins d-flex justify-content-center align-items-center"
    >
      <!--      <span>HI</span>-->
      <div class="bg-white" style="padding: 0 8px">
        <b-img-lazy width="40" height="40" src="../assets/images/icons/cloche.jpeg" />
      </div>
    </div>
    <div class="text-center font-weight-bold font-medium-1" style="margin: 20px 10% 40px">
      Each AKA property offers an exceptional range of services designed to provide residents
      with comfort, choice and luxury for any length of stay!
    </div>
    <b-row class=" " style="">
      <b-col v-for="service of services" :key="service.id" lg="4" md="6" sm="6" xl="3">
        <b-card
          class="local-activities restaurant my-0 px-0 position-relative overflow-auto"
          style="background: none;box-shadow: none; border-radius: 0;"
        >
          <b-aspect aspect="4:3" style="">
            <b-img-lazy
              :src="service.image"
              v-bind="imageProps"
              style="border-radius: 17px;"
              class="h-100 w-100"
              :alt="service.name + ' image'"
            />
          </b-aspect>
          <div
            class="restaurant-details d-flex flex-column justify-content-between h-100"
            style=" width: auto; "
          >
            <!--            Name-->
            <div
              class="text-center text-dark font-medium-4 font-weight-bolder"
              style="margin: 13px;max-height: 68px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"
            >
              {{ service.name }}
            </div>
            <!--            Instructions-->
            <div
              class="d-none text-dark font-medium-1 font-weight-bold ml-1"
              style="margin: 5px 0"
            >
              <span class="d-flex align-items-center w-100">
                <span
                  v-if="
                    (requestedServices.services.includes(service.id) &&
                      requestedServices.data[service.id].instructions) ||
                      deliveryInstructions[service.id]
                  "
                  v-b-modal="service.id"
                  class="ml-0"
                  style="max-width: 80%; max-height: 36px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"
                >
                  {{
                    requestedServices.services.includes(service.id) &&
                    requestedServices.data[service.id].instructions
                      ? requestedServices.data[service.id].instructions
                      : deliveryInstructions[service.id]
                  }}
                </span>
                <span
                  v-else-if="!requestedServices.services.includes(service.id)"
                  v-b-modal="service.id"
                  >Add note{{ deliveryInstructions[service.id] }}</span
                >
                <feather-icon
                  v-if="!requestedServices.services.includes(service.id)"
                  v-b-modal="service.id"
                  icon="EditIcon"
                  size="18"
                  style="min-width: 18px"
                  class="ml-1"
                />
              </span>
            </div>
            <!--            Action Button-->
            <div class="d-none justify-content-start m-1">
              <b-button
                v-if="requestedServices.services.includes(service.id)"
                variant="outline-danger"
                @click="cancelService(service)"
              >
                Cancel Request
              </b-button>
              <b-button
                v-else
                variant="dark"
                style="border-radius: 0"
                @click="requestService(service)"
              >
                Call Service
              </b-button>
            </div>
          </div>
        </b-card>
        <b-modal :id="service.id" centered ok-only ok-title="Save" :title="'Instructions'">
          <b-form-textarea
            id="textarea-state"
            v-model="deliveryInstructions[service.id]"
            :disabled="requestedServices.services.includes(service.id)"
            class="font-weight-bold font-medium-1 text-dark"
            style="border-width: 2px;"
            placeholder="Add any special request (e.g., Leave the food at doorstep, and ring the bell once!, etc.) and the delivery person will do its best to accommodate you."
            rows="5"
          />
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
.local-activities.card {
  border-radius: 2rem;
}
.local-activities > .card-body {
  padding: 5px 0;
}
.local-activities > .card-body .card-body {
  padding: 0;
}
.divider-icon2 {
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0.02, white),
    color-stop(0.5, rgba(0, 0, 0, 0.2)),
    color-stop(0.98, white)
  );
  margin: 0 auto 50px;
}
.divider-margins {
  margin: 0 17px 30px 17px;
}
</style>

<script>
import {
  BImgLazy,
  BCol,
  BRow,
  BModal,
  BFormTextarea,
  BButton,
  BCard,
  BAspect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BImgLazy,
    BModal,
    BCol,
    BRow,
    BFormTextarea,
    BButton,
    BCard,
    BAspect,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
  },
  props: {
    services: {
      type: Array,
      default: () => [
        {
          name: '24 hour front desk',
          id: '24 hour front desk',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/hotel-services/24 hour front desk.jpeg'),
        },
        // {
        //   name: 'Dedicated Doorman',
        //   id: 'Dedicated Doorman',
        //   status: '',
        //   instructions: null,
        //   // eslint-disable-next-line global-require
        //   image: require('@/assets/images/hotel-services/Dedicated Doorman.jpeg'),
        // },
        // {
        //   name: 'Valet cleaning',
        //   id: 'Valet cleaning',
        //   status: '',
        //   instructions: null,
        //   // eslint-disable-next-line global-require
        //   image: require('@/assets/images/hotel-services/Valet cleaning.jpeg'),
        // },
        {
          name: 'Meetings space',
          id: 'Meetings space',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/hotel-services/Meetings space.jpeg'),
        },
        {
          name: 'Rooftop 93',
          id: 'Rooftop 93',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/hotel-services/Rooftop 93.jpeg'),
        },
        {
          name: 'Your Wedding Destination',
          id: 'Your Wedding Destination',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/hotel-services/Your Wedding Destination.jpeg'),
        },
        {
          name: 'In suite dining',
          id: 'In suite dining',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/hotel-services/In suite dining.jpeg'),
        },
        // {
        //   name: 'secure transportation: car rentals, sedan, taxis, and limousine service',
        //   id: 'secure transportation: car rentals, sedan, taxis, and limousine service',
        //   status: '',
        //   instructions: null,
        //   // eslint-disable-next-line global-require
        //   image: require('@/assets/images/hotel-services/secure transportation.jpeg'),
        // },
      ],
    },
  },
  data() {
    return {
      imageProps: {
        center: true,
        blank: true,
        blankColor: '#bbb',
        width: 200,
        height: 200,
        class: '',
      },
      requestedServices: JSON.parse(
        localStorage.getItem('requestedServices') || '{"services":[],"data":{}}'
      ),
      deliveryInstructions: {},
      badges: {
        popular: {
          title: 'POPULAR',
          variant: 'primary',
        },
        fast: {
          title: 'FAST DELIVERY',
          variant: 'primary',
        },
        free: {
          title: 'FREE DELIVERY',
          variant: 'primary',
        },
      },
    }
  },
  created() {},
  methods: {
    cancelService(service) {
      this.requestedServices.services = this.requestedServices.services.filter(
        item => item !== service.id
      )
      delete this.requestedServices.data[service.id]
      localStorage.setItem('requestedServices', JSON.stringify(this.requestedServices))
      this.showError('Request Cancelled Successfully.')
    },
    requestService(service) {
      this.requestedServices.services.push(service.id)
      this.requestedServices.data[service.id] = {
        id: service.id,
        status: 'requested',
        instructions: this.deliveryInstructions[service.id],
      }
      localStorage.setItem('requestedServices', JSON.stringify(this.requestedServices))
      this.orderComplete()
    },
    orderComplete() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: 'Requested Successfully',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    showError(errorMsgText) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: errorMsgText,
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>
